import React from "react";
import "./Home2.css";
import { ReactComponent as WorkIcon } from "../../Assets/work.svg"; // Customize your SVG icons
import { FaUniversity, FaHandsHelping } from "react-icons/fa"; // Add the hands helping icon for volunteering
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="blue"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I love cyber security, it has always been one of my favourite topics, it is so vast and peculiar.
              <br />
              <br />I have been practicing daily in apps like
              <i>
                <b className="blue"> Hack the box, Try hack me and Immersive labs </b>
              </i>
              <br />
              <br />
              My field of Interest's are building new &nbsp;
              <i>
                <b className="blue">Javascript projects and understanding the frameworks of networking </b> and
                also in areas related to{" "}
                <b className="blue">
                  educational app development.
                </b>
              </i>
              <br />
              <br />
              Whenever possible, I also apply my passion for learning new things into vast topics like 
               <b className="blue"> History</b>,
              <i>
                <b className="blue">
                  {" "}
                  Bitcoin and chess.
                </b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>

        {/* Timeline Section */}
        <Row>
          <Col md={12}>
            <VerticalTimeline>
              {/* Summer 2021 */}
              <VerticalTimelineElement
                date="Summer 2021"
                dateClassName="year"
                className="vertical-timeline-element--work"
                icon={<WorkIcon />}
                iconStyle={{ background: "#06D6A0", color: "#fff" }}
              >
                <h3 className="vertical-timeline-element-title" style={{ color: 'black' }}>Completed my Duke of Edinburgh (DofE) Bronze, Silver & Gold award</h3>
                <p style={{ color: 'black' }}>Completed my Duke of Edinburgh (DofE) program, an outdoor adventure challenge. I completed all three expeditions over the summer.</p>
              </VerticalTimelineElement>

              {/* Summer 2020 */}
              <VerticalTimelineElement
                date="Summer 2020"
                dateClassName="year"
                className="vertical-timeline-element--school"
                icon={<FaUniversity />} // School icon
                iconStyle={{ background: "#06D6A0", color: "#fff" }}
              >
                <h3 className="vertical-timeline-element-title" style={{ color: 'black' }}>Attended the CyberFirst Defenders course</h3>
                <p style={{ color: 'black' }}>I attended the CyberFirst Defenders course, where I learned valuable skills in cyber security, including network security and ethical hacking.</p>
              </VerticalTimelineElement>

              {/* Christmas 2022 */}
              <VerticalTimelineElement
                date="Christmas 2022"
                dateClassName="year"
                className="vertical-timeline-element--work"
                icon={<WorkIcon />}
                iconStyle={{ background: "#06D6A0", color: "#fff" }}
              >
                <h3 className="vertical-timeline-element-title" style={{ color: 'black' }}>Attended Kainos Work Experience</h3>
                <p style={{ color: 'black' }}>I completed a work experience program with Kainos, a technology company, where I gained practical knowledge in software development and IT systems.</p>
              </VerticalTimelineElement>

              {/* Last Summer */}
              <VerticalTimelineElement
                date="Summer 2023"
                dateClassName="year"
                className="vertical-timeline-element--work"
                icon={<WorkIcon />}
                iconStyle={{ background: "#06D6A0", color: "#fff" }}
              >
                <h3 className="vertical-timeline-element-title" style={{ color: 'black' }}>Shadowed NHS Cyber Security Department</h3>
                <p style={{ color: 'black' }}>I shadowed the NHS Cyber Security Department, learning about protecting sensitive data and systems in a healthcare environment, and understanding the importance of security protocols in the public sector.</p>
              </VerticalTimelineElement>

              {/* Christmas 2024 (Volunteering) */}
              <VerticalTimelineElement
                date="Christmas 2024"
                dateClassName="year"
                className="vertical-timeline-element--volunteering"
                icon={<FaHandsHelping />} // Volunteering icon
                iconStyle={{ background: "#06D6A0", color: "#fff" }}
              >
                <h3 className="vertical-timeline-element-title" style={{ color: 'black' }}>Helping Seniors with IT Support</h3>
                <p style={{ color: 'black' }}>I will be volunteering during Christmas 2024 by helping seniors near my community with IT support, offering assistance in using technology, setting up devices, and navigating the internet safely.</p>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </Col>
        </Row>

        {/* Social Media Section */}
        <Row style={{ justifyContent: 'center', padding: '20px' }}>
          <Col md={4} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="blue">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/0xMark2005"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillGithub size={40} />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/mark-moore05/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaLinkedinIn size={40} />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/mxrk1550"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram size={40} />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;
