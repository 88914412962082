import React from "react";
import Particles from "react-tsparticles";

function Particle() {
  return (
    <Particles
      id="tsparticles"
      params={{
        particles: {
          number: {
            value: 160,
            density: {
              enable: true,
              value_area: 1500,
            },
          },
          color: {
            value: ["#fffff", "#00ffff"], // Different colors
          },
          shape: {
            type: "circle", // You can also try "edge", "triangle", etc.
          },
          opacity: {
            value: 0.5,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
          },
          line_linked: {
            enable: false,
          },
          move: {
            enable: true,
            direction: "none",
            random: true,
            speed: 1,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
            },
          },
        },
        interactivity: {
          events: {
            onhover: {
              enable: true,
              mode: "grab",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 1,
              },
            },
            push: {
              particles_nb: 5, // Increase the number of particles that spawn on click
            },
            repulse: {
              distance: 200,
              duration: 2,
            },
          },
        },
        retina_detect: true,
      }}
    />
  );
}

export default Particle;
