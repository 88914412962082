import React from "react";
import { Col, Row } from "react-bootstrap";
import CBBHImage from "../../Assets/CBBH.jpg"; // Import image

function CyberStack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={12} md={8} className="tech-icons">
        <Col md={12} style={{ paddingBottom: 20, display: "flex", justifyContent: "center", position: 'relative' }}>
          {/* Image with reduced opacity */}
          <img
            src={CBBHImage}  // Use imported image
            alt="certificate jpg"
            className="img-fluid"
            style={{
              width: "100%", // Make image width 100% of its parent container
              height: "auto", // Automatically adjust the height while keeping aspect ratio
              opacity: 0.7,  // Reduce opacity to make the image a bit transparent
              transition: 'opacity 0.3s', // Smooth transition for opacity
            }}
          />

          {/* Donut chart placed on top of the image */}
          <svg width="200" height="200" viewBox="0 0 200 200" style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // No rotation, just centering the donut
            zIndex: 2, // Ensure the donut is on top of the image
          }}>
            <circle cx="100" cy="100" r="70" stroke="#ddd" strokeWidth="20" fill="none" />
            {/* Animated liquid effect */}
            <circle 
              cx="100" 
              cy="100" 
              r="70" 
              stroke="#4caf50" 
              strokeWidth="20" 
              fill="none" 
              strokeDasharray="440" 
              strokeDashoffset="132" 
              style={{
                animation: 'swayEffect 2s ease-in-out infinite', // Apply the sway animation
              }}
            />
            {/* Adding the percentage text */}
            <text x="50%" y="50%" textAnchor="middle" dy=".3em" fontSize="36" fontWeight="bold" fill="#4caf50">
              70%
            </text>
          </svg>
        </Col>
      </Col>

      {/* Inline style for the sway effect animation */}
      <style>
        {`
          @keyframes swayEffect {
            0% {
              strokeDashoffset: 132;  /* Start point */
            }
            50% {
              strokeDashoffset: 180;  /* Move the liquid forward */
            }
            100% {
              strokeDashoffset: 132;  /* Return to start */
            }
          }
        `}
      </style>
    </Row>
  );
}

export default CyberStack;
