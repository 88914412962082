import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi There ! My name is  <span className="blue"> Mark Moore </span>
             from <span className="blue">  Belfast, UK</span>
            <br />
             I currently am a student at Queen's university studying Software Engineering.
            <br />
             Outside of education I have been obssesed with Cyber Security
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Football
            </li>
            <li className="about-activity">
              <ImPointRight /> Suba Diving
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(6 57 112" }}>
            Thank you,{""}
          </p>
          <footer className="blockquote-footer">Mark Moore</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
